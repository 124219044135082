import React, { useEffect, useState } from "react";
import { User, fetchSignInMethodsForEmail, sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import { useSelector } from "react-redux";
import { getAuth, createUserWithEmailAndPassword, updateProfile } from "firebase/auth";

import {
  ScreenMinWidth,
  useWindowDimensions,
} from "src/hooks/useWindowDimensions";
import { RootState } from "src/store";
import { authService } from "src/services/AuthService";
import { GoogleLoginButton, AppleLoginButton } from "src/components/LoginButtons";

import ShowIcon from "src/assets/password-show.svg"
import HideIcon from "src/assets/password-hide.svg"
import AppLogo from "src/assets/App_Logo.png";
import "./LoginPage.scss";
import { Button, IconButton, InputAdornment, Link, TextField } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";



const LoginPage = ({ formType }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const msgCheckEMail = "Please check your email to verify your account."
  const msgUserExist = "User already exists. Please sign in or use a different email."
  const msgloginFailed = "Login failed. Please check your credentials and try again."
  const msgWrongEmail = "Email format is incorrect."
  const msgWrongPassword = "Password must be 6-30 characters long."
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);

  const { isWide } = useSelector((state) => state.preferences);
  const [loginMessage, setLoginMessage] = useState(null);

  const handleLoginSuccess = (user) => {
    if (!user || !user.uid) return;

    authService.setAuth(user);
    console.log("LOC:::", location.pathname, location.state)
    if (location.pathname === "/login") {
      window.location.replace("/")
    }
    else {
      window.location.replace(location.pathname)
    }

    // window.location.replace("/");
  };

  const handleLoginError = (error: any, provider: any) => {
    /*TODO: do something about the error! */
    setLoginMessage("Failed to login with " + provider);
    console.error("Login Failed: ", provider, error);
  };

  const handleFullNameChange = (e) => {
    setFullName(e.target.value);
  };

  const handleEmailChange = (e) => {


    // Test the email against the regular expression
    const isValid = emailPattern.test(e.target.value);
    setIsEmailValid(isValid);
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    const pass = e.target.value
    const isValid = pass.length >= 6 && pass.length <= 30
    setIsPasswordValid(isValid)
    setPassword(pass);
  };
  const handleForgetPassword = async () => {
    try {
      if (!emailPattern.test(email)) {
        setLoginMessage(msgWrongEmail)
        return
      }
      const auth = getAuth();

      const result = await sendPasswordResetEmail(auth, email)

    }
    catch (e) {

    }
    window.location.replace("/verifyLink");

  }
  const handleSubmit = async () => {
    // Handle form submission based on formType
    // console.log("handleSubmit:",formType)
    if (!emailPattern.test(email)) {
      setLoginMessage(msgWrongEmail)
      return
    }

    if (formType === "register") {
      // Handle registration logic here
      try {
        const auth = getAuth()



        const signInMethods = await fetchSignInMethodsForEmail(auth, email);
        if (signInMethods.length > 0) {

          setLoginMessage(msgUserExist)

          return
        }
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);

        await updateProfile(userCredential.user, { displayName: fullName });

        // Optionally, send email verification
        await sendEmailVerification(userCredential.user);
        window.location.replace("/verifyLink");
        // Clear form fields and error
        // setEmail("");
        // setPassword("");
        // setFullName("");
        // setError(null);

      } catch (error) {
        // setError(error.message);
        console.warn("handleSubmit:", error)

      }
      // Redirect or show success message

    } else if (formType === "login") {
      // Handle login logic here
      try {
        const auth = getAuth()

        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        // console.log("LOGIN::::",userCredential)
        if (userCredential && userCredential.user && !userCredential.user.emailVerified) {
          setLoginMessage(msgCheckEMail)
        }

        handleLoginSuccess(userCredential.user)
        // Clear form fields and error
        setEmail("");
        setPassword("");
        setFullName("");
        // setError(null);

        // Redirect or show success message
      } catch (error) {

        setLoginMessage(msgloginFailed)
      }
    }

  };

  return (
    <div className="loginPageContainer">
      <div className="loginLogo">
        <img src={AppLogo} alt="ARKA" />
        <span>My Health Records</span>
      </div>
      <div className="loginContainer">
        <div className="socialLoginContainer">
          <div className="loginGoogleButtonContainer">
            <GoogleLoginButton
              onError={(err) => handleLoginError(err, "Google")}
              onSuccess={handleLoginSuccess}
              className="loginGoogleButton"
              extraClickAction={() => setLoginMessage(null)}
            />
          </div>
          <div className="loginAppleButtonContainer">
            <AppleLoginButton
              onError={(err) => handleLoginError(err, "Apple")}
              onSuccess={handleLoginSuccess}
              className="loginAppleButton"
              extraClickAction={() => setLoginMessage(null)}
            />
          </div>
        </div>
        <div className="splitter"> or </div>
        <div className="registerContainer">
          {formType === 'register' && (
            <div className="inputDiv">
              <TextField
                placeholder="Full Name"
                variant="outlined"
                value={fullName}
                onChange={handleFullNameChange}
                fullWidth
                inputProps={{ style: { backgroundColor: "white" } }}
              />
            </div>
          )}
          <div className="inputDiv">
            <TextField
              placeholder="Email"
              variant="outlined"
              type="email"
              value={email}
              onChange={handleEmailChange}
              fullWidth
              inputProps={{
                style: { backgroundColor: "white" },
                pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                title: "Please enter a valid email address",
              }}
              error={!isEmailValid}
              helperText={!isEmailValid ? "Invalid email address" : ""}
            />
          </div>
          <div className="inputDiv">
            {formType === "login" && (
              <div className="forgotPassword">
                <Link href="#" onClick={() => handleForgetPassword()}>
                  Forgot Password?
                </Link>
              </div>
            )}
            <TextField
              placeholder="Password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={handlePasswordChange}
              fullWidth
              InputProps={{
                style: { backgroundColor: "white" },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <img alt={showPassword ? "SHOW" : "HIDE"} src={showPassword ? ShowIcon : HideIcon} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={!isPasswordValid}
              helperText={!isPasswordValid ? msgWrongPassword : ""}
            />
          </div>
          <div className="loginButtonContainer">
            <Button
              variant="contained"
              color="primary"
              className="loginButton"
              onClick={() => handleSubmit()}
            >
              {formType === "register" ? "Register" : "Login"}
            </Button>
          </div>
        </div>
      </div>
      {loginMessage && <div className="loginErrorMessage">{loginMessage}</div>}
  
      {/* Footer Section */}
      <footer className="footer">
        <div className="footerText">Download the app:</div>
        <div className="downloadLinks">
          <a
            href="https://play.google.com/store/apps/details?id=com.arkaagp.medirecords"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
              alt="Get it on Google Play"
              className="downloadBadge"
            />
          </a>
          <a
            href="https://apps.apple.com/app/id6739588450"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/3/3c/Download_on_the_App_Store_Badge.svg"
              alt="Download on the App Store"
              className="downloadBadge"
            />
          </a>
        </div>
      </footer>
    </div>
  );
};

LoginPage.defaultProps = {
  formType: "login",
};

export default LoginPage;
