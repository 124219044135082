import React from "react";
import { BoxArrowLeft } from "react-bootstrap-icons";

import { authService } from "src/services/AuthService";

import "./UserProfile.scss";

const UserProfile = ({ className = "", onClick=null ,compactView}) => {
  const user = authService.getUser();
  if (!user) return null;

  const handleLogoutRequest = () => {
    authService.removeAuth();

    window.location.replace("/");
  };

  return (
    <div className={`userProfileMainContainer ${className}`}>
      <div className="userProfileContainer" onClick={onClick}>
        <div className={`userProfileAvatarContainer
                  ${compactView?"userProfileAvatarContainerCompact":""}
            `}  >
          <img
            alt=""
            className={`userProfileAvatar
            ${compactView?"userProfileAvatarCompact":""}
            `}
            src={user.photoURL ? user.photoURL : "/images/blank-profile.png"}
          />
          
        </div>
        {!compactView &&(
          <div className="userProfileTitleContainer" >
            <div className="userProfileTitle">{user.displayName}</div>
            <div className="userProfileEmail">{user.email}</div>
          </div>
        )}
      </div>
      {/* <div className="userProfileLogoutContainer" onClick={handleLogoutRequest}>
        <BoxArrowLeft className="userProfileLogoutIcon" />
        {!compactView &&(
          <span>Logout</span>
        )}
      </div> */}
    </div>
  );
};

export default UserProfile;
