import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import RecordPage from "./pages/RecordPage";
import PersonPage from "./pages/PersonPage";
import Page from "./components/Page";
import { authService } from "./services/AuthService";
import { setUserData } from "./reducers/dataSlice";
import {
  ScreenMinWidth,
  useWindowDimensions,
} from "./hooks/useWindowDimensions";
import { setCompactView, setIsWide, setLoading } from "./reducers/preferencesSlice";
import { RootState } from "./store";
import PersonService from "./services/PersonService";
import DataService from "./services/DataService";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import LoginPage from "./pages/LoginPage";
import VerifyLinkPage from "./pages/VerifyLink";
import AccountPage from "./pages/AccountPage";
import SettingPage from "./pages/SettingPage";
import FeedbackPage from "./pages/FeedbackPage";
import DownloadPage from "./pages/DownloadPage";
import HomeRedirect from "./pages/HomeRedirect";

function App() {
  const dimensions = useWindowDimensions();
  const dispatch = useDispatch();
  const preferences = useSelector((state: RootState) => state.preferences);

  const dataService = new DataService();
  const personService = new PersonService();

  
  const getUserData = async () => {
    dispatch(setLoading(true));
    const uid = authService.getUId();
    if (!uid) return [];

    let data = await dataService.doGetData();
    if (data && data.patients && Object.values(data.patients).length === 0) {
      await personService.storeFirstUser();
      data = await dataService.doGetData();
    }

    dispatch(setUserData(data));
    dispatch(setLoading(false));
  };

  useEffect(() => {
    let wideScrn=dimensions && dimensions.width >= ScreenMinWidth.Wide
    dispatch(setIsWide(wideScrn));
    dispatch(setCompactView(!wideScrn))
  }, [dimensions]);

  useEffect(() => {
    dataService.initialize();
    getUserData();
  }, []);

  return (
    <Router>
      <div
        className={`appContainer ${
          preferences.isWide ? "" : "appContainerSmall"
        }`}
      >
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<LoginPage />} />
            {/* <Route path="/register" element={<LoginPage formType="register" />} /> */}
            <Route path="/verifyLink" element={<VerifyLinkPage />} />
            <Route exact path="/" element={<RecordPage />} />
            <Route path="/home" element={<HomeRedirect />} />
            <Route path="/records" element={<RecordPage />} />
            <Route path="/persons" element={<PersonPage />} />
            <Route path="/account" element={<AccountPage />} />
            <Route path="/settings" element={<SettingPage />} />
            <Route path="/feedback" element={<FeedbackPage />} />
            <Route path="/downloads" element={<DownloadPage />} />
            <Route path="/deleteAccount" element={<AccountPage deleteAccount={true} />} />
          </Routes>

      </div>
    </Router>
  );
}

export default App;
