import React, { useState } from "react";
import { OAuthProvider, User, signInWithPopup } from "firebase/auth";

import { firebaseService } from "src/services/FirebaseService"; // Assuming this is configured
import Button3D from "../Button3D"; // If you use a custom button style, else use a Material UI button
import AppleIcon from "./assets/apple.svg"; // Replace with the Apple logo asset

import "./LoginButtons.scss";
import { Button } from "@mui/material";

interface IAppleLoginButtonProps {
  className?: string;
  extraClickAction?: () => void;
  onError: (error: any) => void;
  onSuccess: (user: User | null) => void;
}

const AppleLoginButton = ({
  className,
  extraClickAction,
  onError,
  onSuccess,
}: IAppleLoginButtonProps) => {
  const [waiting, setWaiting] = useState<boolean>(false);

  const handleLoginRequest = async () => {
    extraClickAction && extraClickAction();
    try {
      setWaiting(true);

      // Initialize the Apple OAuth provider
      const provider = new OAuthProvider("apple.com");
      provider.addScope("email");
      provider.addScope("name");

      const auth = firebaseService.getAuth();
      const result = await signInWithPopup(auth, provider);

      // Extract user info
      const credential = OAuthProvider.credentialFromResult(result);
      if (!credential) {
        throw new Error("Apple-Login Error: No Credentials!");
      }
      const user = result.user;

      // Trigger success callback
      onSuccess(user);
    } catch (error) {
      console.error("Apple-Login Failed: ", error);
      onError(error);
    } finally {
      setWaiting(false);
    }
  };

  return (
    <Button
      className={`appleLoginContainer ${className || ""}`}
      variant="contained"
      color="primary"
      disabled={waiting}
      onClick={handleLoginRequest}
    >
      <div className="appleLoginIconContainer">
        <img alt="A" src={AppleIcon} />
      </div>
      <span className="appleLoginText">Continue with Apple</span>
    </Button>
  );
};

export default AppleLoginButton;
